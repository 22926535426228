.data-protection-main {
  padding: 48px 40px;
  padding-bottom: 100px;

  h1 {
    font-size: 24px;
    font-weight: 800;
  }

  > * {
    margin-bottom: 16px;
  }

  /* Target the numbered headers */
  > ol > li {
    margin-top: 48px;
    margin-left: 16px;
    font-weight: 700;
    text-decoration: underline;
  }

  ol > li {
    padding-left: 8px;
    margin-left: 18px;
  }

  /* Only target the paragraphs after a paragraph */
  p {
    margin-top: 28px;
  }

  .bold {
    font-weight: 700;
  }

  a {
    color: #013768 !important;
    text-decoration: underline !important;
  }
}
