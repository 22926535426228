.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper > div > input {
  width: 100%;
  height: var(--input-height);
  font-size: var(--input-font-size);
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  -webkit-padding-end: var(--input-padding);
  padding-inline-end: var(--input-padding);
  border-radius: var(--input-border-radius);
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  background-color: var(--chakra-colors-white) !important;
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
}

.react-datepicker-wrapper > div > input:hover {
  border-color: var(--chakra-colors-gray-300);
}
