.PhoneInput {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  isolation: isolate;
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
}

.PhoneInputCountry {
  height: var(--input-height);
  font-size: var(--input-font-size);
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  -webkit-padding-end: var(--input-padding);
  padding-inline-end: var(--input-padding);
  border-radius: var(--input-border-radius);
  border: 1px solid;
  border-color: inherit;
  background: var(--chakra-colors-white) !important;
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-inline-end-color: var(--chakra-colors-transparent);
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
}

.PhoneInputInput {
  width: 100%;
  height: var(--input-height);
  font-size: var(--input-font-size);
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  -webkit-padding-end: var(--input-padding);
  padding-inline-end: var(--input-padding);
  border-radius: var(--input-border-radius);
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  background-color: var(--chakra-colors-white) !important;
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.PhoneInputCountry:hover,
.PhoneInputInput:hover {
  border-color: var(--chakra-colors-gray-300);
}
