.terms-main {
  padding: 48px 40px;
  padding-bottom: 100px;

  h1 {
    font-size: 24px;
    font-weight: 800;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
  }

  ol > li {
    margin-left: 16px;
    margin-top: 4px;
  }

  > * {
    margin-bottom: 16px;
  }

  .bold {
    font-weight: 700;
  }

  a {
    color: #013768 !important;
    text-decoration: underline !important;
  }
}
